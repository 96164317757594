<template>
    <div style="height: 100%">
        <v-card flat>
            <v-card-text style=" height: 80vh;">
                <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 100%" v-bind:options="mapStyle"
                    class="mapLocal">
                    <!-- <gmap-cluster> -->
                    <gmap-custom-marker v-for="(location, index) in stock" :key="index" style="z-index: 15"
                        alignment="center" :marker="{lat:location.location.lat, lng: location.location.lng}" :clickable="true">
                        <div @click="setActiveLocation(location)" style="cursor: pointer">
                            <!-- <v-progress-circular :size="66" :width="7" :value="location.inStock" color="#7CB342"
                                class="ms-4">
                                <v-btn @click="setActiveLocation(location)" icon color="black" rounded
                                    style="background: white !important" x-large>
                                    <v-icon>warehouse</v-icon>
                                </v-btn>

                            </v-progress-circular> -->
                            <WarehouseDoughnut :total="location.inStock + location.allocated + location.damaged" :key="location.key" :data="[
                                { value: location.inStock, name: 'On Hand' },
                                { value: location.allocated, name: 'Allocated' },
                                { value: location.damaged, name: 'Damaged' }
                            ]" />
                        </div>
                    </gmap-custom-marker>
                    <!-- </gmap-cluster> -->

                </gmap-map>
                <transition name="slide-info">
                    <v-card v-if="showActivePoint"
                        style="background-color: var(--v-modal-base) !important;border-radius: 10px; " class="infoCard">
                        <v-card-title class="text-left" v-if="activePoint.location"
                            style="font-size: 16px">
                            <span>
                                {{ activePoint.location.name }}
                            </span>
                        </v-card-title>
                        <v-card-subtitle
                            v-if="activePoint.location && activePoint.location && activePoint.location.address">
                            <v-icon class="mr-2" color="grey" small>location_on</v-icon> {{
                                activePoint.location.address }}
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list subheader dense>
                                <v-row>
                                    <v-col cols="4">
                                        <v-card color="component" style="border-radius: 25px">
                                            <v-card-text class="ma-0 pb-0">
                                                <span class="ml-1" style="font-size: 14px">On Hand</span>
                                                <v-toolbar flat dense>
                                                    <b>{{ activePoint.inStock }}</b>
                                                    <v-spacer></v-spacer>
                                                    <v-icon color="grey">list_alt</v-icon>

                                                </v-toolbar>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>


                                    <v-col cols="4">
                                        <v-card color="component" style="border-radius: 25px">
                                            <v-card-text class="ma-0 pb-0">
                                                <span class="ml-1" style="font-size: 14px">Allocated</span>
                                                <v-toolbar flat dense>
                                                    <b>{{ activePoint.allocated }}</b>
                                                    <v-spacer></v-spacer>
                                                    <v-icon color="success">task_alt</v-icon>
                                                </v-toolbar>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="4">
                                        <v-card color="component" style="border-radius: 25px">
                                            <v-card-text class="ma-0 pb-0">
                                                <span class="ml-1" style="font-size: 14px">Damaged</span>
                                                <v-toolbar flat dense>
                                                    <b>{{ activePoint.damaged }}</b>
                                                    <v-spacer></v-spacer>
                                                    <v-icon color="red">cancel</v-icon>
                                                </v-toolbar>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-subheader style="font-size: 16px">Product Overview</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item v-for="product in activePoint.stock" :key="product.productId"
                                    @click="viewProductDetail(product)">

                                    <v-list-item-content>
                                        <v-list-item-title>{{ product.productName }}</v-list-item-title>
                                        <!-- <v-progress-linear :color="productStatusColor(product)" :value="(product.inStock/(product.inStock+product.reserved))*100"></v-progress-linear> -->
                                        <div class="progress-container">
                                            <div v-for="i in 5" :key="i" class="segment"
                                                :style="{ background: segmentColor(i, product) }" />
                                        </div>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <v-icon class="mr-2" color="grey" small>list_alt</v-icon>
                                            {{ product.inStock }} On Hand
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            <v-icon class="mr-2 pb-1" color="grey" small>task_alt</v-icon>
                                            {{ product.allocated }} Allocated
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            <v-icon class="mr-2 pb-1" color="grey" small>cancel</v-icon>
                                            {{ product.damaged }} Damaged
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon><v-icon>chevron_right</v-icon></v-btn>
                                    </v-list-item-action>

                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </transition>
                <transition name="slide-options">
                    <v-card v-if="showMapOptions"
                        style="background-color: var(--v-modal-base) !important;border-radius: 10px; " class="keyCard">
                        <v-toolbar dense flat color="transparent">
                            <div>
                                <div
                                    style="width: 15px; height: 15px; background-color: #4CAF50 !important; border-radius: 50%">
                                </div>
                            </div>
                            <h4 class="ml-3">In Stock</h4>
                            <div class="ml-3">
                                <div style="width: 15px; height: 15px; background-color: #03A9F4; border-radius: 50%">
                                </div>
                            </div>
                            <h4 class="ml-3">Allocated</h4>
                            <div class="ml-3">
                                <div style="width: 15px; height: 15px; background-color: #F44336; border-radius: 50%">
                                </div>
                            </div>
                            <h4 class="ml-3">Damaged</h4>
                        </v-toolbar>
                    </v-card>
                </transition>
            </v-card-text>
        </v-card>

        <v-dialog v-model="productDetailModal" width="1500px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="productDetailModal">
                <v-toolbar flat>
                    <v-toolbar-title>
                        {{ productDetail.productName }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="productDetailModal = false, productDetail = null, showActivePoint = true, showMapOptions = true"
                        text>X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-tabs v-model="productTab">
                        <v-tab href="#ProductBreakdown">
                            Product Breakdown
                        </v-tab>
                        <v-tab href="#InventoryList">
                            Inventory List
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="productTab">
                        <v-tab-item value="ProductBreakdown">
                            <v-card flat style="border-radius:0%">
                                <v-card-text style="max-height: 70vh; overflow-y: auto">
                                    <ProductBreakdownTable :headers="productSummaryHeaders" :items="productDetailTable"
                                        :tableKey="productDetailKey" :loading="loadingStockDetail" :height="'100%'" />
                                </v-card-text>
                            </v-card>

                        </v-tab-item>
                        <v-tab-item value="InventoryList">
                            <v-card flat style="border-radius:0%">
                                <v-card-text>
                                    <v-data-table height="70vh" fixed-header disable-pagination hide-default-footer dense
                                        :items="productDetail.detail" :headers="productDetailHeaders">
                                        <template v-slot:[`item.status`]="{ item }">
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small style="text-transform: none" v-bind="attrs" v-on="on"
                                                        :color="getStatusColor(item.status)">
                                                        {{ item.status }}
                                                    </v-btn>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item v-for="(status, index ) in statusOptions" :key="index"
                                                        :style="{ 'border-left': `5px solid ${status.color}` }"
                                                        @click="setPalletStatus(item.sscc, status.value)"
                                                        style="height: 30px">
                                                        <v-list-item-content class="text-left ml-0 pl-0">
                                                            <v-list-item-title>{{ status.name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import ProductBreakdownTable from '../../SystemComponents/ProductBreakdownTable.vue'
import WarehouseDoughnut from '../../Charts/WarehouseDoughnut.vue'
export default {
    props: ['stock'],
    components: {
        GmapCustomMarker,
        ProductBreakdownTable,
        WarehouseDoughnut
    },
    data: () => ({
        activePoint: null,
        center: { lat: -30.005682, lng: 24.467975 },
        loadingStockDetail: false,
        mapStyle: {
            scrollwheel: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
                {
                    featureType: "poi.business",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                { elementType: "geometry", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563", visibility: "off", }],
                },
                {
                    featureType: "administrative",
                    elementType: "geometry",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563", visibility: "off", }],
                },
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [
                        { visibility: "off" }
                    ]
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#263c3f", visibility: "off", }],
                },
                {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#6b9a76", visibility: "off", }],
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [{ color: "#38414e", visibility: "off", }],
                },
                {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#212a37", visibility: "off", }],
                },
                {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#9ca5b3", visibility: "off", }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ color: "#746855", visibility: "off", }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#1f2835", visibility: "off", }],
                },
                {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#f3d19c", visibility: "off", }],
                },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#2f3948" }],
                },
                {
                    featureType: "transit.station",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#212121" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#515c6d" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.stroke",
                    stylers: [{ color: "#17263c" }],
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fff"
                        }
                    ]
                }
            ],
        },
        productSummaryHeaders: [
            {
                text: "Product",
                value: "productName",
                align: "center",
            },
            {
                text: "Variety",
                value: "varietyName",
                align: "center",
            },
            {
                text: "Pack Code",
                value: "packCode",
                align: "center",
            },
            {
                text: "Grade/Class",
                value: "grade",
                align: "center",
            },

            {
                text: "Count/Size",
                value: "countSize",
                align: "center",
            },
            {
                text: "No. Cartons",
                value: "cartonQuantity",
                align: "center",
            },
            {
                text: "Percentage",
                value: "percentage",
                align: "center",
            },
            // {
            //     text: "Price per Carton",
            //     value: "cartonPrice",
            //     align: "center",
            // },
            // {
            //     text: "Price per KG",
            //     value: "kgPrice",
            //     align: "center",
            // },
        ],
        productDetailHeaders: [
            {
                text: "Status",
                align: "center",
                value: "status",
            },
            {
                text: "SSCC",
                align: "center",
                value: "sscc",
            },
            {
                text: "Sequence No.",
                align: "center",
                value: "sequenceNo",
            },
            {
                text: "Variety",
                align: "center",
                value: "varietyProduct.name"
            },
            { text: "Pack Code", align: 'center', value: "packCode" },
            { text: "Grade", align: 'center', value: "grade" },
            { text: "Count/Size", align: 'center', value: "countSize" },
            { text: "Mark", align: 'center', value: "mark" },
            { text: "Inventory Code", align: 'center', value: "inventoryCode" },
            {
                text: "No. Cartons",
                value: "cartonQuantity",
                align: "center",
            },
            { text: "Intake Date", align: 'center', value: "intakeTime" },
            { text: "Store", align: 'center', value: "store" },
            { text: "Bay", align: 'center', value: "bay" },
            { text: "PUC", align: 'center', value: "puc" },
            { text: "PHC", align: 'center', value: "phc" },
            { text: "Orchard", align: 'center', value: "orchard" }


            // { text: "Pallets", align: 'center', value: "total" }
        ],
        productDetail: null,
        productDetailKey: 1000,
        productDetailModal: false,
        productDetailTable: [],
        productTab: null,
        showActivePoint: false,
        showMapOptions: true,
        statusOptions: [
            {
                name: "In Stock",
                value: "In Stock",
                color: '#4CAF50'
            },
            {
                name: "Allocated",
                value: "Allocated",
                color: '#2196F3'
            },
            {
                name: "Damaged",
                value: "Damaged",
                color: '#F44336'
            },
        ],
        zoom: 6
    }),
    methods: {
        getStatusColor(status) {
            switch (status) {
                case 'In Stock':
                    return 'green'
                case 'Allocated':
                    return 'blue'
                case 'Damaged':
                    return 'red'
                default:
                    return 'grey'
            }
        },
        async getStockDetailTable(data) {
            this.loadingStockDetail = true
            this.productDetailTable = await this.$API.getStockDetailTable({
                params: {
                    productId: data.productId,
                    locationCode: this.activePoint.locationCode
                }
            })
            this.productDetailKey++
            this.loadingStockDetail = false
        },
        productStatusIcon(product) {
            let value = (product.inStock / (product.inStock + product.reserved)) * 100
            console.log(value)
            if (value > 80) {
                return 'sentiment_very_satisfied'
            } else if (value > 60) {
                return 'sentiment_satisfied'
            } else if (value > 40) {
                return 'sentiment_neutral'
            } else if (value > 20) {
                return 'sentiment_dissatisfied'
            } else if (value > 0) {
                return 'sentiment_very_dissatisfied'
            }
        },
        productStatusColor(segmentMatch) {
            switch (segmentMatch) {
                case 1:
                    return '#F44336'
                case 2:
                    return '#FF9800'
                case 3:
                    return '#FFC107'
                case 4:
                    return '#8BC34A'
                case 5:
                    return '#4CAF50'
            }
        },
        segmentColor(i, product) {
            let value = (product.inStock / (product.inStock + product.allocated)) * 100

            let defaultColor = 'rgb(217, 217, 217)'
            let segmentMatch = Math.ceil(value / 20)
            console.log(value, segmentMatch)
            let color = this.productStatusColor(segmentMatch)
            if (i <= segmentMatch) {
                return color
            } else if (i > segmentMatch) {
                return defaultColor
            }
        },
        setActiveLocation(location) {
            this.activePoint = location
            this.showActivePoint = true
        },
        async setPalletStatus(sscc, status) {
            let update = []
            this.productDetail.detail.forEach((detail, index) => {
                if (detail.sscc === sscc) {
                    this.productDetail.detail[index].status = status
                    update.push({
                        id: detail.id,
                        status: status
                    })
                }
            })
            this.productDetail.inStock = [...new Set(this.productDetail.detail.filter(detail => detail.status === 'In Stock').map(x=>x.sscc))].length
            this.productDetail.allocated = [...new Set(this.productDetail.detail.filter(detail => detail.status === 'Allocated').map(x=>x.sscc))].length
            this.productDetail.damaged = [...new Set(this.productDetail.detail.filter(detail => detail.status === 'Damaged').map(x=>x.sscc))].length
            this.updateStatusCount()
            await this.$API.updateStockItems({ batch: update })
            this.$message.success('Status updated!')
        },
        updateStatusCount(){
            this.activePoint.inStock = this.activePoint.stock.map(x=>x.inStock).reduce((a,b)=>a+b, 0)
            this.activePoint.allocated = this.activePoint.stock.map(x=>x.allocated).reduce((a,b)=>a+b, 0)
            this.activePoint.damaged = this.activePoint.stock.map(x=>x.damaged).reduce((a,b)=>a+b, 0)
            if(!this.activePoint.key){
                this.activePoint.key = 1
            }
            this.activePoint.key ++
        },
        viewProductDetail(product) {
            this.productDetail = product
            this.productDetailModal = true
            this.showActivePoint = false
            this.showMapOptions = false
            this.getStockDetailTable(product)
        },
    }
}
</script>
<style scoped>
.progress-container {
    display: flex;
}

.segment {
    width: 2rem;
    height: 0.5rem;
    background: rgb(217, 217, 217);
}

.segment:not(:first-child) {
    margin-left: 2px;
}

.segment:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.segment:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.slide-info-leave-active {
    transition: 0.5s;
}

.slide-info-enter-active {
    transition: 0.8s;
}

.slide-info-enter {
    transform: translateX(100%);
}

.slide-info-leave-to {
    transform: translateX(100%);
}

.slide-options-leave-active {
    transition: 0.3s;
}

.slide-options-enter-active {
    transition: 0.5s;
}

.slide-options-enter {
    transform: translateY(-120%);
}

.slide-options-leave-to {
    transform: translateY(-120%);
}

.v-progress-circular__underlay {
    stroke: #FF9800;
    z-index: 1
}

.infoCard {
    position: absolute;
    border-radius: 16px;
    width: 33vw;
    top: 3vh;
    right: 0.5vw;
    z-index: 2000 !important;
}

.keyCard {
    position: absolute;
    border-radius: 16px;
    width: auto;
    top: 3vh;
    left: 2vw;
    z-index: 2000 !important;
}
</style>